module.exports = {
  siteTitle: 'Al-Itqaan',
  manifestName: 'alitqaan2',
  manifestShortName: 'main',
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/`,
  heading: 'Al-Itqaan Center',
  subHeading: 'CENTER FOR THE STUDY OF QURANIC SCIENCES',
  // social
  socialLinks: [
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'admin@alitqaan.net',
    },
    {
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://www.facebook.com/itqancntr',
    },
  ],
  phone: '612-702-8562',
  address: '12119 16th Ave S, Burnsville, MN 55337',
};
