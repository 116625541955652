import React from 'react';
import config from '../../config';
const Footer = ({ NoFooter }) => {
  return (
    <section id="footer">
      <div className="inner">
        {NoFooter ? null : (
          <ul className="contact">
            <li className="fa-home">{config.address}</li>
            <li className="fa-phone">{config.phone}</li>
          </ul>
        )}
        <ul className="copyright">
          <li>&copy; Al-Itqaan Center</li>
        </ul>
      </div>
    </section>
  );
};

export default Footer;
